//Custom function variable defined
var hpApp = {};
var connection_string = socket_url + ':' + socket_port;
var socket = io.connect(connection_string, {secure: true});
if (encrypted_user_id) {
    socket.emit('new_user', encrypted_user_id);
}
$(function () {
    //language change mobile radio button
    $('.language-bar-mobile .switch-blue input[type="checkbox"]').on('change', function () {
        var $this = $(this);
        if ($this.is(':checked')) {
            window.location.href = api_url + '/lang/en'
        } else {
            window.location.href = api_url + '/lang/fr'
        }
    });
    //END language change mobile radio button
    /**
     * Place the CSRF token as a header on all pages for access in AJAX requests
     */
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
        }
    });
    //Navbar 
    $('.header--navigation .dropdown').on('mouseenter', function () {
        var $this = $(this);
        if (!$this.hasClass('loginClick')) {
            $this.addClass('active');
            $this.siblings('div').show();
        }
    });
    $('.header--navigation .dropdown').on('mouseleave', function () {
        var $this = $(this);
        if (!$this.hasClass('loginClick')) {
            $this.removeClass('active');
            $this.siblings('div').hide();
        }
    });
//    notification-----------------------------------------------
    //closing and opening notification box
    $(document).mouseup(function (e) {
        var container = $('.dropdown-click.notification');
        if (!container.is(e.target) && container.has(e.target).length === 0) {
            container.find('.hnavitem-dropdown-trigger').removeClass('active');
            container.find('.notification-dropdown').removeClass('open');
        }
        if (e.target.className == 'hnavitem-dropdown-trigger active' || ((e.target.className == 'noti-number' || e.target.className == 'msgimage') && container.find('.hnavitem-dropdown-trigger').hasClass('active'))) {
            container.find('.hnavitem-dropdown-trigger').removeClass('active');
            container.find('.notification-dropdown').removeClass('open');
        } else if (e.target.className == 'hnavitem-dropdown-trigger' || e.target.className == 'noti-number' || e.target.className == 'msgimage') {
            container.find('.hnavitem-dropdown-trigger').addClass('active');
            container.find('.notification-dropdown').addClass('open');
        }
    });
    $('.notification-all').on('click', '.cross-btn', function () {
        readNotifications(user_notification_url_read.replace("0", $(this).data('id')), $(this));
    });
    function getUserNotifications(url) {
        $.ajax({
            url: url,
            type: 'GET',
            dataType: 'json',
            success: function (resp) {
                $('.notification-all').empty();
                var notificationTemplate = $('#notification-template').html();
                notificationTemplate = _.template(notificationTemplate);
                $.each(resp, function (i, notification) {
                    var data = {
                        notification: notification
                    };
                    $('.notification-all').append(notificationTemplate(data));
                });
                if (resp.length > 0) {
                    $('.noti-number').show().text(resp.length);
                } else {
                    $('.notification-all').append('<div class="no-new-notification"><p>No New Notification</p></div>');
                }
            },
            error: function (error) {
                hpApp.ajaxSwalError(error);
            }
        });
    }
    function readNotifications(url, $this) {
        $.ajax({
            url: url,
            type: 'GET',
            dataType: 'json',
            success: function (resp) {
                $this.closest('.notification-single').slideUp();
                var initnumber = parseInt($('.noti-number').first().text());
                $('.noti-number').text(initnumber - 1);
            },
            error: function (error) {
                hpApp.ajaxSwalError(error);
            }
        });
    }
    if (user_notification_url) {
        getUserNotifications(user_notification_url);
    }
//    end notification -----------------------
    $('.hnavitem-dropdown').on('mouseenter', function () {
        var $this = $(this);
        if (!$this.hasClass('login-form')) {
            $this.show();
            $this.siblings('.dropdown').addClass('active');
        }
    });
    $('.hnavitem-dropdown').on('mouseleave', function () {
        var $this = $(this);
        if (!$this.hasClass('login-form')) {
            $this.hide();
            $this.siblings('.dropdown').removeClass('active');
        }
    });
    $(document).on('click', '.loginClick', function () {
        $(this).addClass('active');
        $('.login-form').show();
    });
    $(document).click(function (e) {
        var container = $(".login-form");
        var containerDiv = $(".loginClick");
        // if the target of the click isn't the container nor a descendant of the container
        if ((!container.is(e.target) && container.has(e.target).length === 0) && (!containerDiv.is(e.target) && containerDiv.has(e.target).length === 0))
        {
            $('.login-form').hide();
            $('.loginClick').removeClass('active');
        }
    });
    //Mobile Navbar
    $('.open-sub-menu').on('click', function () {
        $(this).toggleClass('active').next().slideToggle(100);
    });
    //END navbar
    //Login nav   
    $('#login-nav-form').on('submit', function (e) {
        var $this = $(this);
        //disabling submit button
        $this.find('input[type="submit"]').attr('disabled', 'disabled');

        $.ajax({
            type: "POST",
            url: $this.attr('action'),
            data: $this.serialize(),
            dataType: 'json',
            success: function (response) {
                //removing all error classes
                $this.find('.has-error').removeClass('has-error');
                $this.find('.error-msg').remove();

                $this.find('input[type="submit"]').removeAttr('disabled');
                $this.find('[type="password"], [type="email"]').val('');

                location.href = response.url;
            },
            error: function (error) {
                var formSel = $('#login-nav-form');
                formSel.find('input[type="submit"]').removeAttr('disabled');
                hpApp.ajaxInputError(error, formSel);
            }
        });
        e.preventDefault(e);
    });
    //END Login nav
    //mobile navbar btn   
    $('#open-navbar').on('click', function () {
        $(this).find('.crossbtn').toggleClass('show');
        $('#' + $(this).data('toggleid')).slideToggle(50);
    });
    //END mobile navbar
    //Custom function
    hpApp.ajaxSwalError = function (error) {
        if ($.isEmptyObject(error)) {
            swal({
                title: 'Oops!!',
                text: 'Something went wrong. Please try again.',
                type: 'error',
                customClass: 'sweat-alert-confirm'
            });
        } else {
            var error_text = '';
            if (!$.isEmptyObject(error.responseText)) {
                var responseText = JSON.parse(error.responseText);
                $.each(responseText, function (i, v) {
                    error_text = error_text + '<br>' + v;
                });
            } else {
                error_text = 'Something went wrong. Please try again.';
            }
            swal({
                title: 'Oops!!',
                text: error_text,
                type: 'error',
                customClass: 'sweat-alert-confirm',
                html: true
            });
        }
    };
    hpApp.ajaxInputError = function (error, formSel) {
        //removing all error classes
        formSel.find('.has-error').removeClass('has-error');
        formSel.find('.error-msg').remove();
        if ($.isEmptyObject(error)) {
            swal({
                title: 'Oops!!',
                text: 'Something went wrong. Please try again.',
                type: 'error',
                customClass: 'sweat-alert-confirm'
            });
        } else {
            if (!$.isEmptyObject(error.responseText)) {
                var responseText = JSON.parse(error.responseText);
                $.each(responseText, function (i, v) {
                    if ($.isArray(v)) {
                        var textValue = '';
                        $.each(v, function (i, value) {
                            textValue = textValue + ' ' + value;
                        });
                    } else {
                        var textValue = v;
                    }
                    formSel.find('[name="' + i + '"]').addClass('has-error').after('<div class="error-msg">' + textValue + '</div>');
                });
            }
        }
    };
    hpApp.arrangeRightSidebar = function () {
        var windowWidth = $(window).width();
        if (windowWidth > 1366) {
            var rightCalc = (parseInt(windowWidth) - 1366) / 2,
                    adBlockswidth = (1366 * 0.3555) - 20;
            $('.adblocks').outerWidth(adBlockswidth).css({right: rightCalc});
        } else if (windowWidth < 1366 && windowWidth > 1200) {
            var adBlockswidth = parseInt(windowWidth) * 0.34;
            $('.adblocks').outerWidth(adBlockswidth).css({right: 0});
        } else if (windowWidth < 1200 && windowWidth > 768) {
            $('.adblocks').outerWidth('33.33%').css({right: 0});
        } else if (windowWidth < 768) {
            $('.adblocks').outerWidth('100%').css({right: 0}).siblings().height('auto');
        }
        setTimeout(function () {
            $('.adblocks').css({opacity: 1});
        }, 200);
    };
    hpApp.arrangeLeftHeight = function (container) {
        if ($(window).width() > 768) {
            var adblock_height = $('.adblocks').height();
            if (container == null) {
                container = $('.adblocks').siblings();
            }
            container.height('auto');
            if (adblock_height > container.height()) {
                adblock_height = parseInt(adblock_height) - 20;
                container.height(adblock_height);
            }

        }
    };
    hpApp.twoDecimal = function (num) {
        var num = parseFloat(num);
        if (num > 0 || num < 0) {
            if (num % 1 === 0) {
                return num;
            } else {
                return num.toFixed(2);
            }
        } else if (num == 0) {
            return 0;
        } else if (num == '') {
            return 0;
        }
    };
    hpApp.twoDecimalFixed = function (num) {
        return parseFloat(num).toFixed(2);
    };
    
    hpApp.threeDecimal = function (num) {
        if (num != 0) {
            var num = parseFloat(num);
            if (num % 1 === 0) {
                return num;
            } else {
                return num.toFixed(3);
            }
        } else if (num == 0) {
            return 0;
        } else if (num == '') {
            return 0;
        }
    };
    hpApp.getOrdinal = function (n) {
        var s = ["th", "st", "nd", "rd"],
                v = n % 100;
        return n + '<sup>' + (s[(v - 20) % 10] || s[v] || s[0]) + '</sup>';
    };
    hpApp.boxStandardStatsSetting = function () {
        $('.pools--box').each(function (i, v) {
            var $this = $(this);
            if ($this.find('.points').length == 0) {
                //if points div is not present
                if ($(window).width() > 768) {
                    $this.find('.ranks').css({width: '20%'});
                    $this.find('.stats').css({width: 'calc(80% - 25px)'});
                    //if stat table is bigger than outer div
                    if ($this.find('.stats').width() < $this.find('.stats table').width()) {
                        $this.find('.ranks').css({width: '100%'});
                        $this.find('.stats').css({width: 'calc(100% - 25px)'});
                    }
                } else {
                    $this.find('.ranks').css({width: '98%'});
                    $this.find('.stats').css({width: '100%'});
                }
            } else {
                //if points div is present
                if ($(window).width() > 768) {
                    $this.find('.ranks').css({width: '10%'});
                    $this.find('.points').css({width: '30%'});
                    $this.find('.stats').css({width: 'calc(60% - 25px)'});
                    //if stat table is bigger than outer div
                    if ($this.find('.stats').width() < $this.find('.stats table').width()) {
                        $this.find('.ranks').css({width: '43%'});
                        $this.find('.points').css({width: '50%'});
                        $this.find('.stats').css({width: 'calc(100% - 25px)'});
                    }
                } else {
                    $this.find('.ranks').css({width: '43%'});
                    $this.find('.points').css({width: '50%'});
                    $this.find('.stats').css({width: '100%'});
                }
            }
        });
    };
    hpApp.h2hStatsSetting = function () {
        var window_width = $(window).width();
        if (window_width > 991) {
            $('.pools--h2h .bottom').each(function () {
                var $this = $(this),
                        totalNum = $this.find('.width10').length,
                        calcWidth = 100 / parseInt(totalNum);
                $this.find('.width10').outerWidth(calcWidth + '%');
            });
        } else if (window_width < 991 && window_width > 500) {
            $('.width10').outerWidth('20%');
        } else {
            $('.width10').outerWidth('33.33333%');
        }
    };
    hpApp.isElementEmpty = function (el) {
        return !$.trim(el.html());
    };
    hpApp.replaceStringToLink = function (val) {
        var exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
        return val.replace(exp, "<a href='$1' target='_blank'>$1</a>");
    };
    hpApp.replaceAll = function (str, search, replacement) {
        return str.split(search).join(replacement);
    };
    hpApp.millisToMinutesAndSeconds = function (millis) {
        var minutes = Math.floor(millis / 60000),
                seconds = ((millis % 60000) / 1000).toFixed(0);
        return {minutes: minutes, seconds: (seconds < 10 ? '0' : '') + seconds};
    };
    hpApp.countDownTimer = function (time, sel, complete) {
        clearInterval(countDownInterval);
        var start = new Date().getTime();
        countDownInterval = setInterval(function () {
            var now = time - (new Date().getTime() - start);
            if (now <= 0) {
                clearInterval(countDownInterval);
                if (typeof complete != 'undefined') {
                    complete();
                }
            } else {
                var minsSecs = hpApp.millisToMinutesAndSeconds(now);
                sel.text(minsSecs.minutes + ':' + minsSecs.seconds);
            }
        }, 100);
    };

    //END Custom function
});