var tweet_template = $('.tweet_tem').html();
if (typeof tweet_template != 'undefined') {
    tweet_template = _.template(tweet_template);
}

socket.on('TwitterFeed', function (twitterData) {
    if (typeof tweet_template != 'undefined') {
        var upcoming_tweets = $('.upcoming-tweets');
        $('.upcoming-tweets .tweets--loader').siblings().remove();
        for (var x = 0; x < twitterData.length; x++) {
            var checkAdded = $('.upcoming-tweets').find('[data-tweet_id="' + twitterData[x].tweet_id + '"]');
            if (checkAdded.length == 0) {
                var tweet_data =
                        {
                            image: twitterData[x].image,
                            name: twitterData[x].name,
                            tweet: hpApp.replaceStringToLink(twitterData[x].tweet),
                            created: twitterData[x].tweet_created,
                            tweet_id: twitterData[x].tweet_id
                        };
                upcoming_tweets.append(tweet_template(tweet_data));
                var tweetDiv = $('.upcoming-tweets').find('[data-tweet_id="' + twitterData[x].tweet_id + '"]').find('.discussion');
                if (!$.isEmptyObject(twitterData[x].comments)) {
                    if (twitterData[x].comments.length > 0) {
                        for (var y = 0; y < twitterData[x].comments.length; y++) {
                            tweetDiv.append('<div class="each-comment">' + twitterData[x].comments[y].comment + '<div><span class="name">' + twitterData[x].comments[y].name + '</span><span class="created">' + twitterData[x].comments[y].created_at + '</span></div></div>');
                        }
                    }
                }
            }
        }
        if (twitterData.length == 0) {
            upcoming_tweets.append('<p class="no-result-present">No Tweets Found</p>');
        }
    }
    $('.tweets--loader').hide();
});
function firstTwitterFeedData() {
    $.ajax({
        url: api_url + '/firsttwitterfeed',
        type: 'GET',
        success: function (resp) {

        },
        error: function (resp) {

        }
    });
}
$(document).ready(function () {
    //Comment on each tweet function
    $(document).on('click', '.showreply', function (e) {
        $(this).closest('.description').find('.replay').slideToggle(200);
        //Rethink again
//        setTimeout(function () {
//            hpApp.arrangeLeftHeight(null);
//        }, 210);
        e.preventDefault();
    });
    $(document).on('submit', '#twitter-comment-form', function (e) {
        var $this = $(this),
                comment = $this.find('input[name="comment"]').val();
        $this.find('button').attr('disabled', 'disabled');
        if (comment.length > 0) {
            $.ajax({
                url: $this.attr('action'),
                type: 'POST',
                dataType: 'json',
                data: $this.serialize(),
                success: function (response) {
                    $this.parent().slideToggle(200);
                    $this.find('button').removeAttr('disabled');
                    $this.find('input[name="comment"]').val('');

                    $this.parents('.home-public--news--tweet').find('.discussion').prepend('<div class="each-comment">' + comment + '<div><span class="name">' + response.name + '</span><span class="created">' + response.created + '</span></div></div>');
                },
                error: function (error) {
                    $('#twitter-comment-form').find('button').removeAttr('disabled');
                    hpApp.ajaxSwalError(error);
                }
            });
        }
        e.preventDefault();
    });
    //getting first twitter feed
    var twitterHit = window.setInterval(function () {
        if (socket.connected) {
            firstTwitterFeedData();
            clearInterval(twitterHit);
        }
    }, 1000);
});