$(document).ready(function () {
    var checkHeight = setInterval(function () {
        if ($('.upcoming-tweets').height() >= '180') {
            //Arranging height of left and sidebar 
            var leftSide = $('.home-public--pool-type'),
                    adblocks = $('.adblocks');
            leftSide.height('auto');
            adblocks.height('auto');
            if (leftSide.height() > adblocks.height()) {
                adblocks.height(leftSide.height());
            } else {
                leftSide.height(adblocks.height());
            }
            clearInterval(checkHeight);
        }
    }, 200);

    $(window).resize(function () {
        var leftSide = $('.home-public--pool-type'),
                adblocks = $('.adblocks');
        leftSide.height('auto');
        adblocks.height('auto');
        if (leftSide.height() > adblocks.height()) {
            adblocks.height(leftSide.height());
        } else {
            leftSide.height(adblocks.height());
        }
    });
});