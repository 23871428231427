//var scoreboardFirstTime = true;
$(document).ready(function () {
    //Scoreboard to today match div 
    function goScoreboardToday() {
        var prev2Width = $('.livescoreboard--scores--previous2').width(),
                prevWidth = $('.livescoreboard--scores--previous').width(),
                yestWidth = $('.livescoreboard--scores--yesterday').width(),
                totalWidth = (prev2Width > 100 ? prev2Width : 0) + (prevWidth > 100 ? prevWidth : 0) + (yestWidth > 100 ? yestWidth : 0);
        if (totalWidth > 0) {
            $('.livescoreboard--scores').css('margin-left', -totalWidth + 'px');
        }
    }
    //END
    //Live Scores
    $('.livescoreboard--right-arrow .graphics').on('click', function () {
        var fullOuterDiv = $('.livescoreboard--scores'),
                eachSlideDiv = $('.livescoreboard--scores .each--slide'),
                eachSlideWidth = eachSlideDiv.outerWidth(),
                doAnimate = true;

        if (!fullOuterDiv.is(':animated')) {
            var diff1 = (parseFloat($('.outer-parent').width()) - parseFloat($('.main_header').width())) + 50;
            if (parseInt(fullOuterDiv.css('margin-left')) < -diff1) {
                //means last slide -- no animate
                doAnimate = false;
            }
            if (doAnimate == true) {
                fullOuterDiv.animate({
                    marginLeft: '-=' + $('.livescoreboard--scores-parts').outerWidth() + 'px'
                });
            }
        }
    });
    $('.livescoreboard--left-arrow .graphics').on('click', function () {
        var fullOuterDiv = $('.livescoreboard--scores'),
                doAnimate = true;

        if (!fullOuterDiv.is(':animated')) {
            if (fullOuterDiv.css('margin-left') == '0px') {
                //means first slide
                doAnimate = false;
            }
            if (doAnimate == true) {
                fullOuterDiv.animate({
                    marginLeft: '+=' + $('.livescoreboard--scores-parts').outerWidth() + 'px'
                });
            }
        }
    });
    //Live Scores End
    //scoreboard data coming from sockets
    
    //Notification
    socket.on('notification', function (notification) {
        var notificationTemplate = $('#notification-template').html();
        notificationTemplate = _.template(notificationTemplate);
        var data = {
            notification: notification.data.notification
        };
        $('.notification-all').prepend(notificationTemplate(data));
    });
    //END
    socket.on('ScoreboardFeed', function (scorefeed_data) {
        var scoreboard_past = $('.scoreboard_past').html();
        scoreboard_past = _.template(scoreboard_past);

        var scoreboard_future = $('.scoreboard_future').html();
        scoreboard_future = _.template(scoreboard_future);

        var scoreboard_today = $('.scoreboard_today').html();
        scoreboard_today = _.template(scoreboard_today);
        var livescoreboard_scores = $('.livescoreboard--scores');

        //adding today_live_feed
        var livescoreboard_scores_today = $('.livescoreboard--scores--today');
        if (!$.isEmptyObject(scorefeed_data.today_live_feed)) {
            $('.livescoreboard--scores--today .each--slide').remove();
            for (var x = 0; x < scorefeed_data.today_live_feed.length; x++) {
                var api_id = scorefeed_data.today_live_feed[x].api_id;
                if (livescoreboard_scores.find('[data-match_id="' + api_id + '"]').length == 0) {
                    var append = {
                        match_id: api_id,
                        home_image: api_url + scorefeed_data.today_live_feed[x].home_image,
                        home_abbr: scorefeed_data.today_live_feed[x].home_abbr,
                        home_goals: scorefeed_data.today_live_feed[x].home_team_score,
                        match_status: scorefeed_data.today_live_feed[x].match_status,
                        away_image: api_url + scorefeed_data.today_live_feed[x].away_image,
                        away_abbr: scorefeed_data.today_live_feed[x].away_abbr,
                        away_goals: scorefeed_data.today_live_feed[x].away_team_score,
                        match_complition_type: scorefeed_data.today_live_feed[x].match_complition_type,
                        competition_id: scorefeed_data.today_live_feed[x].id
                    };
                    livescoreboard_scores_today.append(scoreboard_today(append));
                    livescoreboard_scores_today.show();
                }
            }
        }

        //adding yesterday_feed
        var livescoreboard_scores_yesterday = $('.livescoreboard--scores--yesterday');
        if (!$.isEmptyObject(scorefeed_data.yesterday_feed)) {
            $('.livescoreboard--scores--yesterday .each--slide').remove();
            for (var x = 0; x < scorefeed_data.yesterday_feed.length; x++) {
                var api_id = scorefeed_data.yesterday_feed[x].api_id;
                if (livescoreboard_scores.find('[data-match_id="' + api_id + '"]').length == 0) {
                    var append = {
                        match_id: api_id,
                        home_image: api_url + scorefeed_data.yesterday_feed[x].home_image,
                        home_abbr: scorefeed_data.yesterday_feed[x].home_abbr,
                        home_goals: scorefeed_data.yesterday_feed[x].home_team_score,
                        match_status: scorefeed_data.yesterday_feed[x].match_status,
                        away_image: api_url + scorefeed_data.yesterday_feed[x].away_image,
                        away_abbr: scorefeed_data.yesterday_feed[x].away_abbr,
                        away_goals: scorefeed_data.yesterday_feed[x].away_team_score,
                        match_complition_type: scorefeed_data.yesterday_feed[x].match_complition_type,
                        competition_id: scorefeed_data.yesterday_feed[x].id
                    };
                    livescoreboard_scores_yesterday.append(scoreboard_past(append));
                    livescoreboard_scores_yesterday.show();
                }
            }
        }

        //adding previous_feed
        var livescoreboard_scores_previous = $('.livescoreboard--scores--previous');
        var livescoreboard_scores_previous2 = $('.livescoreboard--scores--previous2');
        if (!$.isEmptyObject(scorefeed_data.previous_feed)) {
            for (var x = 0; x < scorefeed_data.previous_feed.length; x++) {
                var div_to_append;
                if ($('.livescoreboard--scores--previous .previous span').text() == 'Date') {
                    div_to_append = livescoreboard_scores_previous;
                    $('.livescoreboard--scores--previous .previous span').text(scorefeed_data.previous_feed[x].match_start_date);
                } else if ($('.livescoreboard--scores--previous .previous span').text() == scorefeed_data.previous_feed[x].match_start_date) {
                    div_to_append = livescoreboard_scores_previous;
                } else {
                    //go to .livescoreboard--scores--previous2
                    if ($('.livescoreboard--scores--previous2 .previous span').text() == 'Date') {
                        div_to_append = livescoreboard_scores_previous2;
                        $('.livescoreboard--scores--previous2 .previous span').text(scorefeed_data.previous_feed[x].match_start_date);
                    } else if ($('.livescoreboard--scores--previous2 .previous span').text() == scorefeed_data.previous_feed[x].match_start_date) {
                        div_to_append = livescoreboard_scores_previous2;
                    }
                }
                div_to_append.find('.each--slide').remove();
            }

            for (var x = 0; x < scorefeed_data.previous_feed.length; x++) {
                var api_id = scorefeed_data.previous_feed[x].api_id;
                if (livescoreboard_scores.find('[data-match_id="' + api_id + '"]').length == 0) {

                    var div_to_append;
                    if ($('.livescoreboard--scores--previous .previous span').text() == 'Date') {
                        div_to_append = livescoreboard_scores_previous;
                        $('.livescoreboard--scores--previous .previous span').text(scorefeed_data.previous_feed[x].match_start_date);
                    } else if ($('.livescoreboard--scores--previous .previous span').text() == scorefeed_data.previous_feed[x].match_start_date) {
                        div_to_append = livescoreboard_scores_previous;
                    } else {
                        //go to .livescoreboard--scores--previous2
                        if ($('.livescoreboard--scores--previous2 .previous span').text() == 'Date') {
                            div_to_append = livescoreboard_scores_previous2;
                            $('.livescoreboard--scores--previous2 .previous span').text(scorefeed_data.previous_feed[x].match_start_date);
                        } else if ($('.livescoreboard--scores--previous2 .previous span').text() == scorefeed_data.previous_feed[x].match_start_date) {
                            div_to_append = livescoreboard_scores_previous2;
                        }
                    }

                    var append = {
                        match_id: api_id,
                        home_image: api_url + scorefeed_data.previous_feed[x].home_image,
                        home_abbr: scorefeed_data.previous_feed[x].home_abbr,
                        home_goals: scorefeed_data.previous_feed[x].home_team_score,
                        match_status: scorefeed_data.previous_feed[x].match_status,
                        away_image: api_url + scorefeed_data.previous_feed[x].away_image,
                        away_abbr: scorefeed_data.previous_feed[x].away_abbr,
                        away_goals: scorefeed_data.previous_feed[x].away_team_score,
                        match_complition_type: scorefeed_data.previous_feed[x].match_complition_type,
                        competition_id: scorefeed_data.previous_feed[x].id
                    };
                    div_to_append.append(scoreboard_past(append));
                    div_to_append.show();
                }
            }
        }

        //adding tomorrow_feed
        var livescoreboard_scores_future = $('.livescoreboard--scores--future'),
                livescoreboard_scores_future_dayafter = $('.livescoreboard--scores--future--dayafter'),
                livescoreboard_scores_future_dayafter2 = $('.livescoreboard--scores--future--dayafter--second');
        if (!$.isEmptyObject(scorefeed_data.tomorrow_feed)) {
            //removing old appended scores
            for (var x = 0; x < scorefeed_data.tomorrow_feed.length; x++) {
                var div_to_append;
                if (scorefeed_data.tomorrow_feed[x].what_day == 'tomorrow') {
                    div_to_append = livescoreboard_scores_future;
                }
//                else if (scorefeed_data.tomorrow_feed[x].what_day == 'today') {
//                    div_to_append = livescoreboard_scores_today;
//                }
                else {
                    var first_check = $('.livescoreboard--scores--future--dayafter .dayafter span').text();
                    if (first_check == 'null') {
                        div_to_append = livescoreboard_scores_future_dayafter;
                        $('.livescoreboard--scores--future--dayafter .dayafter span').text(scorefeed_data.tomorrow_feed[x].match_start_date2);
                    } else {
                        div_to_append = livescoreboard_scores_future_dayafter2;
                        $('.livescoreboard--scores--future--dayafter--second .dayafter span').text(scorefeed_data.tomorrow_feed[x].match_start_date2);
                    }
                }
                div_to_append.find('.each--slide').remove();
            }

            for (var x = 0; x < scorefeed_data.tomorrow_feed.length; x++) {
                var api_id = scorefeed_data.tomorrow_feed[x].api_id;
                if (livescoreboard_scores.find('[data-match_id="' + api_id + '"]').length == 0) {

                    var div_to_append;
                    if (scorefeed_data.tomorrow_feed[x].what_day == 'tomorrow') {
                        div_to_append = livescoreboard_scores_future;
                    } else if (scorefeed_data.tomorrow_feed[x].what_day == 'today') {
                        div_to_append = livescoreboard_scores_today;
                    } else {
                        var first_check = $('.livescoreboard--scores--future--dayafter .dayafter span').text();
                        if (first_check == scorefeed_data.tomorrow_feed[x].match_start_date2) {
                            div_to_append = livescoreboard_scores_future_dayafter;
                        } else {
                            div_to_append = livescoreboard_scores_future_dayafter2;
                        }
                    }

                    var append = {
                        match_id: api_id,
                        home_image: api_url + scorefeed_data.tomorrow_feed[x].home_image,
                        home_abbr: scorefeed_data.tomorrow_feed[x].home_abbr,
                        match_start_date: scorefeed_data.tomorrow_feed[x].match_start_date,
                        away_image: api_url + scorefeed_data.tomorrow_feed[x].away_image,
                        away_abbr: scorefeed_data.tomorrow_feed[x].away_abbr,
                        competition_id: scorefeed_data.tomorrow_feed[x].id
                    };
                    div_to_append.append(scoreboard_future(append));
                    div_to_append.show();
                }
            }
        }
        $('.livescoreboard--loader').hide();

        //Taking the scoreboard to today matches
//        if (scoreboardFirstTime) {
        goScoreboardToday();
//            scoreboardFirstTime = false;
//        }
    });
    //End scoreboard data
    //getting first scoreboard data
    function firstscoreboarddata() {
        $.ajax({
            url: api_url + '/firstscoreboarddata',
            type: 'GET',
            success: function (resp) {
            },
            error: function (resp) {
            }
        });
    }
    var scoreboardHit = window.setInterval(function () {
        if(socket.connected){
            firstscoreboarddata();
            clearInterval(scoreboardHit);
        }
    }, 1000);
    //End getting first scoreboard data

    $(window).on('resize', function () {
        goScoreboardToday();
    });
});